import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4><a parentName="h4" {...{
        "href": "/"
      }}>{`home`}</a>{` / `}<a parentName="h4" {...{
        "href": "/resume"
      }}>{`resume`}</a>{` / `}<a parentName="h4" {...{
        "href": "/projects"
      }}>{`projects`}</a></h4>
    <hr></hr>
    <h1>{`>_ test`}</h1>
    <h2>{`List:`}</h2>
    <ol>
      <li parentName="ol">{`one`}</li>
      <li parentName="ol">{`two`}</li>
    </ol>
    <h3>{`Italics`}</h3>
    <p>{`And thats how the `}<em>{`cookie crumbles`}</em></p>
    <h3>{`Links:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/samroy92"
      }}>{`Github`}</a></p>
    <h3>{`Code blocking:`}</h3>
    <p><inlineCode parentName="p">{`this is a test of code`}</inlineCode>{`
`}<inlineCode parentName="p">{`npm -i samroy.io`}</inlineCode>{`
`}{`[code]`}{`test`}{`[/code]`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "sam": "roy",
    "foo": "bar"
}
`}</code></pre>
    <h5><a parentName="h5" {...{
        "href": "/"
      }}>{`back to home`}</a>{` / `}<a parentName="h5" {...{
        "href": "#top"
      }}>{`top of page`}</a></h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      